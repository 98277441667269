<template>
  <base-section id="user-config" space="0">
    <v-container>
      <UserAddresses />
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "UserAddressesSettings",
  components: {
    UserAddresses: () => import("@/components/userSettings/UserAddresses.vue"),
  },
  provide: {
    heading: { align: "center" },
  },
};
</script>
